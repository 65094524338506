import React from 'react';

import {Link} from 'react-router-dom';

import ATM from 'Root/images/atm-default.jpg';

const NotFoundSection = () => {
  document.title = 'Goanna - Inside The Bank';

  return (
    <>
      <div className="atm">
        <div className="machine">
          <Link to={`/account`} className="nfts">My NFTs</Link>
          <Link to={`/account#loans`} className="loans">My Loans</Link>
          <div className="imgs change">
            <img src={ATM} className="img-fluid w-100" />
          </div>
        </div>
      </div>
      <div className="bestview d-block d-lg-none p-5 text-center">
        <Link to={`/account`} className="btn btn-primary btn-lg">
          My NFTs
        </Link>&nbsp;
        <Link to={`/account#loans`} className="btn btn-primary btn-lg">
          My Loans
        </Link>
      </div>
    </>
  );
};

export default NotFoundSection;
