import React from 'react';

import WelcomeSection from '../components/Sections/WelcomeSection/Welcome';

const Welcome = () => {
  return (
    <>
      <WelcomeSection />
    </>
  );
};

export default Welcome;
