import React from 'react';

import {Link} from 'react-router-dom';

import Bank from 'Root/images/bank.jpg';

const WelcomeSection = () => {
  document.title = 'Goanna Lending';

  return (
    <>
      <div className="bank">
        <Link to={`/bank`} className="enter">Enter</Link>
        <img src={Bank} className="img-fluid mx-auto w-100" />
      </div>
      <div className="bestview d-block d-lg-none p-5 text-center">
        <Link to={`/bank`} className="btn btn-primary btn-lg">
          Enter Bank
        </Link>
      </div>
    </>
  );
};

export default WelcomeSection;
