import {closePeraWalletSignTxnToast} from '@perawallet/connect';

import algosdk from 'algosdk';
import {formatJsonRpcRequest} from '@json-rpc-tools/utils';

import {base64Encode} from 'Root/helpers/base64';

import {SHOW_WALLET_CONNECT_MODAL} from 'Root/services/constants/ActionTypes';

/**
 * Function to sign transactions with MyAlgo or PeraConnect depending on the session/localStorage
 * @param {string} address Address of the user signing the transaction
 * @param {Object} peraConnect Object that allows the user to interact with PeraConnect
 * @param {Object} deflyConnect Objec that allows the user to interact with DeflyConnect
 * @param {Object} myAlgoWallet Object that allows the user to interact with MyAlgo
 * @param {Object} walletConnect Object that allows the user to interact with regular WalletConnect
 * @param {Function} showWalletConnectModal Function that shows modal when signing transactions with WalletConnect
 * @param {Function} closeModal Function that closes any modal
 * @param {Object[]} transactions Array of transactions to sign
 * @return {Object[]} Array of Base64 encoded signed transactions
 */
export async function signTransactions(address, peraConnect, deflyConnect, myAlgoWallet, walletConnect,
    showWalletConnectModal, closeModal, transactions) {
  let transactionsToSend = [];
  let signedTransactions;

  if (peraConnect.isConnected) {
    const transactionsToSign = transactions.map((txn) => {
      return {
        txn: txn,
        signers: [address],
      };
    });
    try {
      const signedTransactions = await peraConnect.signTransaction([transactionsToSign]);
      signedTransactions.forEach((signedTransaction) => {
        transactionsToSend.push(base64Encode(signedTransaction));
      });
    } catch (e) {

    }
    closePeraWalletSignTxnToast();
  } else if (deflyConnect.isConnected) {
    const transactionsToSign = transactions.map((txn) => {
      return {
        txn: txn,
        signers: [address],
      };
    });
    try {
      const signedTransactions = await deflyConnect.signTransaction([transactionsToSign]);
      signedTransactions.forEach((signedTransaction) => {
        transactionsToSend.push(base64Encode(signedTransaction));
      });
    } catch (e) {

    }
    closePeraWalletSignTxnToast();
  } else if (localStorage.getItem('myAlgoAddress')) {
    signedTransactions = await myAlgoWallet.signTransaction(transactions.map((txn) => txn.toByte()));
    signedTransactions.forEach((signedTransaction) => {
      transactionsToSend.push(base64Encode(signedTransaction.blob));
    });
  } else if (localStorage.getItem('walletconnect')) {
    const txnsToSign = transactions.map((txn) => {
      const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64');
      return {
        txn: encodedTxn,
        message: 'Shufl Transaction',
      };
    });

    const request = formatJsonRpcRequest('algo_signTxn', [txnsToSign]);
    try {
      showWalletConnectModal();
      transactionsToSend = await walletConnect.sendCustomRequest(request);
    } catch (e) {
      console.log(e);
    }
    closeModal(SHOW_WALLET_CONNECT_MODAL);
  } else if (window.exodus?.algorand?.isConnected) {
    signedTransactions = await window.exodus.algorand.signTransaction(transactions.map((txn) => txn.toByte()));
    signedTransactions.forEach((signedTransaction) => {
      transactionsToSend.push(base64Encode(signedTransaction));
    });
  }

  return transactionsToSend;
}
