import React from 'react';

import WalletSection from '../components/Sections/WalletSection/Wallet';

const Wallet = () => {
  return (
    <>
      <div className="my-nfts">
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12 col-md middle py-4 mx-0 mx-md-4">
              <div className="scroll px-4">
                <WalletSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
