import {CONNECT_WALLET, DISCONNECT_WALLET} from '../../constants/ActionTypes';

import MyAlgoConnect from '@randlabs/myalgo-connect';
import {PeraWalletConnect} from '@perawallet/connect';
import {DeflyWalletConnect} from '@blockshake/defly-connect';

import WalletConnect from '@walletconnect/client';
import QRCodeModal from 'algorand-walletconnect-qrcode-modal';

const defaultState = null;

export const peraConnect = new PeraWalletConnect();
export const deflyConnect = new DeflyWalletConnect();
export const myAlgoWallet = new MyAlgoConnect();
export const walletConnect = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org',
  qrcodeModal: QRCodeModal,
});

export const connect = (state = defaultState, action) => {
  switch (action.type) {
    case CONNECT_WALLET:
      return {
        ...state,
        address: action.address,
      };
    case DISCONNECT_WALLET:
      peraConnect.disconnect();
      deflyConnect.disconnect();
      if (walletConnect.connected) {
        walletConnect.killSession();
      }
      localStorage.removeItem('myAlgoAddress');
      localStorage.removeItem('walletconnect');
      localStorage.removeItem('exodusWallet');
      return {
        ...state,
        address: null,
      };
    default:
      return state;
  }
};
