import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {Link, useLocation} from 'react-router-dom';

import axios from 'axios';
import PropTypes from 'prop-types';

import {openConnectModal, connectWallet, disconnectWallet} from 'Root/services/actions/actions';

import {peraConnect, deflyConnect, walletConnect} from 'Root/services/reducers/connect/connect';

import Logo from 'Root/images/logo.webp';
import TokenLogo from 'Root/images/1387238831.png';
import Note from 'Root/images/note.jpg';

const Navbar = ({address, peraConnect, deflyConnect, walletConnect,
  showConnectModal, connectWallet, disconnectWallet}) => {
  const location = useLocation();
  const [goannaBalance, setGoannaBalance] = useState(0);
  const [goannaBalanceLoading, setGoannaBalanceLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('myAlgoAddress') !== null) {
      connectWallet(localStorage.getItem('myAlgoAddress'));
    }

    walletConnect.on('connect', (error, payload) => {
      if (error) {
        throw error;
      }
      const {accounts} = payload.params[0];
      connectWallet(accounts[0]);
      setGoannaBalance(0);
    });

    walletConnect.on('session_update', (error, payload) => {
      if (error) {
        throw error;
      }
      const {accounts} = payload.params[0];
      connectWallet(accounts[0]);
      setGoannaBalance(0);
    });

    walletConnect.on('disconnect', (error, _payload) => {
      disconnectWallet();
      if (error) {
        throw error;
      }
    });

    if (localStorage.getItem('exodusWallet') !== null) {
      try {
        window.exodus.algorand.connect({onlyIfTrusted: true}).then((res) => {
          connectWallet(res.address);
          setGoannaBalance(0);
        });
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
      }
    }

    peraConnect.reconnectSession().then((accounts) => {
      if (accounts.length) {
        connectWallet(accounts[0]);
        setGoannaBalance(0);
      }
      // Setup the disconnect event listener
      peraConnect.connector?.on('disconnect', () => {
        disconnectWallet();
      });
    });

    deflyConnect.reconnectSession().then((accounts) => {
      if (accounts.length) {
        connectWallet(accounts[0]);
      }
      // Setup the disconnect event listener
      deflyConnect.connector?.on('disconnect', () => {
        disconnectWallet();
      });
    });
  }, []);

  useEffect(() => {
    if (address) {
      setGoannaBalanceLoading(true);
      axios.get(process.env.REACT_APP_API_ADDRESS + `getGoannaCoin?public_key=${address}`).then((response) => {
        setGoannaBalance(response.data);
      }).finally(() => {
        setGoannaBalanceLoading(false);
      });
    }
  }, [address]);

  return (
    <nav className="navbar navbar-expand-lg bg-nav px-md-5 py-3">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={Note} className="img-fluid note1" width="60px" />
          <img src={Note} className="img-fluid note2" width="60px" />
          <img src={Note} className="img-fluid note3" width="60px" />
          <img src={Logo} className="img-fluid logo" width="140px" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <i className="fa-solid fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={`/`} className={`nav-link ${location.pathname == '/' && ('active')}`}
                aria-current="page">Welcome</Link>
            </li>
            {address && (
              <li className="nav-item">
                <Link to={`/account`} className={`nav-link ${location.pathname == '/account' && ('active')}`}
                  aria-current="page">My Account</Link>
              </li>
            )}
            <li className="nav-item">
              <Link to={`/faq`} className={`nav-link ${location.pathname == '/faq' && ('active')}`}
                aria-current="page">FAQ</Link>
            </li>
          </ul>
          {address && (
            <div className="m-2 p-1 rounded border border-dark">
              {!goannaBalanceLoading ? (
                <>
                  <img src={TokenLogo} className="img-fluid p-1" width='50px' />
                  {goannaBalance >= 0 ? (
                    <span>Goanna Balance: {(goannaBalance).toFixed(6)}</span>
                  ) : (
                    <>
                      <span>Warning!</span>
                      <span>&nbsp; You are not opted into 1387238831/$GOANNA</span>
                    </>
                  )}
                </>
              ) : (
                <div className='text-center'>
                  Loading...
                </div>
              )}
            </div>
          )}
          <form className="d-flex" role="connect">
            {address ? <button type="button"
              className="btn btn-danger"
              onClick={disconnectWallet}>Disconnect</button> :
              <a onClick={showConnectModal} className="btn btn-primary btn-sm px-3">Connect</a>}
          </form>
        </div>
      </div>
    </nav>
  );
};


const mapStateToProps = (state) => ({
  address: state.connect?.address,
  peraConnect: peraConnect,
  deflyConnect: deflyConnect,
  walletConnect: walletConnect,
});

const mapDispatchToProps = (dispatch) => ({
  showConnectModal: () => dispatch(openConnectModal()),
  connectWallet: (address) => dispatch(connectWallet(address)),
  disconnectWallet: () => dispatch(disconnectWallet()),
});

Navbar.propTypes = {
  address: PropTypes.string,
  peraConnect: PropTypes.object,
  deflyConnect: PropTypes.object,
  walletConnect: PropTypes.object,
  showConnectModal: PropTypes.func,
  connectWallet: PropTypes.func,
  disconnectWallet: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
