import React from 'react';

import {useNavigate} from 'react-router-dom';

const NotFoundSection = () => {
  document.title='Not Found';
  document.body.classList.add('home');

  const navigate = useNavigate();

  return (
    <div className='container'>
      <div className="p-3 p-md-4 p-lg-5">
        <div className="row align-items-center g-5 mb-5 text-center">
          <div className="col-12 text-center">
            <h1 className="text-dark my-4">404<br/>Not Found...</h1>
            <button onClick={() => navigate(-1)} className='btn btn-primary btn-lg'>Go Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundSection;
