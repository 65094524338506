import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

import {shortenAddress} from 'Root/helpers/shortenAddress';

import {myAlgoWallet, walletConnect} from 'Root/services/reducers/connect/connect';
import {openLoadingModal, closeModal} from 'Root/services/actions/actions';

import NFTCard from 'Root/components/NFTCard/NFTCard';

import ClipLoader from 'react-spinners/ClipLoader';

import algosdk from 'algosdk';

import axios from 'axios';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

const WalletSection = ({address}) => {
  const [assets, setAssets] = useState([]);
  const [currentAssets, setCurrentAssets] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(false);

  const [filterCollection, setFilterCollection] = useState('all');
  const [loanedAssets, setLoanedAssets] = useState([]);
  const [loanedAssetsLoading, setLoanedAssetsLoading] = useState(false);
  const [nfd, setNfd] = useState([]);

  useEffect(() => {
    if (address) {
      document.title='My Account';
      setAssetsLoading(true);
      axios.get(process.env.REACT_APP_API_ADDRESS + `getLoanAssets?public_key=${address}`).then((response) => {
        setAssets(response.data);
        setCurrentAssets(response.data);
      }).finally(() => {
        setAssetsLoading(false);
      });

      axios.get(process.env.REACT_APP_API_ADDRESS + `getLoans?public_key=${address}`).then((response) => {
        setLoanedAssets(response.data);
      }).finally(() => {
        setLoanedAssetsLoading(false);
      });

      axios.get(`https://api.nf.domains/nfd/address?address=${address}&view=full&limit=1`).then((response) => {
        const nfdData = response.data[0];
        if (nfdData.properties.verified.avatar) {
          nfdData.properties.verified.avatar =
            nfdData.properties.verified.avatar.replace('ipfs://',
                'https://images.nf.domains/ipfs/');
        }
        if (nfdData.properties.verified.banner) {
          nfdData.properties.verified.banner =
          nfdData.properties.verified.banner.replace('ipfs://', 'https://ipfs.algonft.tools/ipfs/');
        }
        if (nfdData.properties.userDefined.banner) {
          nfdData.properties.userDefined.banner =
          nfdData.properties.userDefined.banner.replace('ipfs://', 'https://ipfs.algonft.tools/ipfs/');
        }
        setNfd(nfdData);
      }).catch(() => {
        setNfd([]);
      });
    }
  }, [address]);

  useEffect(()=>{
    // Add locale once
    TimeAgo.addLocale(en);
  }, []);

  /**
  * Function to reload assets on wallet page
  */
  function reload() {
    axios.get(process.env.REACT_APP_API_ADDRESS + `getLoanAssets?public_key=${address}`).then((response) => {
      setAssets(response.data);
      setCurrentAssets(response.data);
    });

    axios.get(process.env.REACT_APP_API_ADDRESS + `getLoans?public_key=${address}`).then((response) => {
      setLoanedAssets(response.data);
    }).finally(() => {
      setLoanedAssetsLoading(false);
    });
  }

  const {hash} = window.location;

  return (
    address ? (
      <>
        {nfd.properties ? (
          <>
            <div className='row align-items-center g-4 mb-4'>
              <div className='col-auto'>
                {nfd.properties.verified?.avatar && (
                  <>
                    <img src={nfd.properties.verified.avatar}
                      className='img-fluid rounded-circle' width='60'/>
                  </>
                )}
                {nfd.properties.userDefined?.avatar && (
                  <>
                    <img src={nfd.properties.userDefined.avatar}
                      className='img-fluid rounded-circle' width='60'/>
                  </>
                )}
                {!nfd.properties.verified.avatar && !nfd.properties.userDefined.avatar && (
                  <img src='https://app.nf.domains/img/nfd-image-placeholder_gold.jpg'
                    className='img-fluid rounded-circle' width='60'/>
                )}
              </div>
              <div className='col-9 col-md'>
                {nfd.name ? (
                  <h3 className="text-truncate mb-0">{nfd.name}</h3>
                ):(
                  <h3 className="text-truncate mb-0 text-white">{shortenAddress(address)}</h3>
                )}
                <p className="text-truncate text-white mb-0 small">
                  <i className="fa-light fa-wallet"></i> {address}</p>
              </div>
            </div>
          </>
          ):(
            <div className='row align-items-center g-0'>
              <div className='col-auto'>
                <img src='https://app.nf.domains/img/nfd-image-placeholder_gold.jpg'
                  className='img-fluid rounded-circle me-3' width='60'/>
              </div>
              <div className='col'>
                <h3 className="text-truncate mb-0">{shortenAddress(address)}</h3>
                <p className="text-truncate mb-0 small text-white">
                  <i className="fa-light fa-wallet"></i> {address}</p>
              </div>
              <div className='col-12 pt-3'></div>
            </div>
          )}
        <ul className="nav nav-pills pt-1  mb-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${hash == '' && ( 'active' )}`} id="tradeable-tab"
              data-bs-toggle="tab" data-bs-target="#tradeable"
              type="button" role="tab" aria-controls="tradeable" aria-selected="true">
                  My NFTs ({currentAssets.length})
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${hash == '#loans' && ( 'active' )}`} id="listings-tab" data-bs-toggle="tab"
              data-bs-target="#listings"
              type="button" role="tab" aria-controls="listings"
              aria-selected="false">My Loans ({Object.entries(loanedAssets).length})</button>
          </li>
        </ul>
        <div className="tab-content" id="walletTabs">
          <div className={`tab-pane fade ${hash == '' && ( 'show active' )}`}
            id="tradeable" role="tabpanel" aria-labelledby="tradeable-tab">
            <div className='pb-5 d-flex align-items-center justify-content-end'>
              {currentAssets.length > 0 && (
                <div className='flex-grow-1 text-white'>
                  {currentAssets.length} NFT{currentAssets.length !== 1 && 's'} available for liquidity...
                </div>
              )}
            </div>
            {filterCollection !== 'all' && (
              <>
                <span className="badge bg-brand text-black p-2 me-2 mb-4 d-inline-block position-relative pe-4">
                  <span className='text-dark'>{filterCollection}</span>
                  <button onClick={() => {
                    setFilterCollection('all');
                    setCurrentAssets(assets);
                    document.getElementById('collection').value ='all';
                    [].forEach.call(document.querySelectorAll(' input[type=radio]'), function(el) {
                      el.checked = false;
                    });
                  }}
                  className='btn bg-none border-0 btn-close-trait rounded-pill
              position-absolute top-50 end-0 translate-middle-y me-1'>
                    <i className="fa-solid fa-xmark"></i>
                  </button></span>
              </>
            )}
            <div className='row g-4'>
              {!assetsLoading ? (
                  currentAssets.length > 0 ? (
                      currentAssets.map((asset) => (
                        <>
                          <NFTCard key={asset.name} id={asset.id} listingId={asset.app_id}
                            name={asset.name} collection={asset.collection} image={asset.image}
                            rank={asset.rank}
                            loanamount={asset.loanamount}
                            price={asset.price && parseInt(asset.price)} owner={asset.owner} small
                            isOwner={address === address} amount={asset.amount}
                            reload={reload}
                          />
                        </>
                      ))
                  ) :
                  (
                    <div className="col-12">
                      <h2 className='text-center pt-4 mb-5'>No NFTs Found</h2>
                    </div>
                  )
                ) :
                (
                  <div className="col-12 justify-content-center text-center align-items-center w-100 h-100 pt-5">
                    <ClipLoader size={128} color="#fff" />
                  </div>
                )}
            </div>
          </div>
          <div className={`tab-pane fade ${hash == '#loans' && ( 'show active' )}`} id="listings" role="tabpanel"
            aria-labelledby="listings-tab">
            <div className='pb-5 d-flex align-items-center justify-content-end'>
              {Object.entries(loanedAssets).length > 0 && (
                <div className='flex-grow-1 text-muted'>
                  {Object.entries(loanedAssets).length} Liquidity Loans found...
                </div>
              )}
            </div>
            <div className='row g-4'>
              {!loanedAssetsLoading ? (
                  Object.entries(loanedAssets).length > 0 ? (
                    Object.entries(loanedAssets)?.map((_foundLoanedAssets, asset) => (
                      <NFTCard key={loanedAssets[asset].name} id={loanedAssets[asset].id}
                        loanId={loanedAssets[asset].app_id} name={loanedAssets[asset].name}
                        collection={loanedAssets[asset].collection} image={loanedAssets[asset].image}
                        rank={loanedAssets[asset].rank}
                        loanamount={loanedAssets[asset].loanamount}
                        price={algosdk.microalgosToAlgos(loanedAssets[asset].params['global-state'].find(
                            (state) => state.key === 'Z2xvYmFsX2xlbmRfcGF5YmFjaw==',
                        ).value.uint - loanedAssets[asset].params['global-state'].find(
                            (state) => state.key === 'Z2xvYmFsX2xlbmRfcGFpZA==',
                        ).value.uint)}
                        owner={loanedAssets[asset].owner}
                        isOwner={address === address} amount={loanedAssets[asset].amount} reload={reload}
                        loanLength={loanedAssets[asset].params['global-state'].find(
                            (state) => state.key === 'Z2xvYmFsX2xlbmRfdGltZQ==',
                        ).value.uint}
                        endDate={loanedAssets[asset].params['global-state'].find(
                            (state) => state.key === 'Z2xvYmFsX2xlbmRfZGF0ZQ==',
                        ).value.uint + loanedAssets[asset].params['global-state'].find(
                            (state) => state.key === 'Z2xvYmFsX2xlbmRfdGltZQ==',
                        ).value.uint}
                      />
                    ))
                  ) :
                  (
                    <div className="col-12">
                      <h2 className='text-center pt-4 mb-5'>No Lent NFTs Found</h2>
                    </div>
                  )
                ) :
                (
                  <div className="col-12 justify-content-center text-center align-items-center w-100 h-100 pt-5">
                    <ClipLoader size={128} color="#fff" />
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    ) : (
      <p>Account Not Found. Please Connect Your Wallet</p>
    )
  );
};

const mapStateToProps = (state) => ({
  address: state.connect?.address,
  myAlgoWallet: myAlgoWallet,
  walletConnect: walletConnect,
});

const mapDispatchToProps = (dispatch) => ({
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
});

WalletSection.propTypes = {
  address: PropTypes.string,
  myAlgoWallet: PropTypes.object,
  walletConnect: PropTypes.object,
  closeModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletSection);
