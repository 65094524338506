import React from 'react';

import BankSection from '../components/Sections/BankSection/Bank';

const Bank = () => {
  return (
    <>
      <BankSection />
    </>
  );
};

export default Bank;
