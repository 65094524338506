/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const FAQ = () => {
  document.title='FAQ';
  return (
    <div className='container'>
      <div className="p-3 p-md-4 p-lg-5">
        <div className="col-12 text-center">
          <h1 className="text-dark mb-0">FAQ</h1>
        </div>
        <p className="text-center">You can use your NFTs as collateral to take an Algo based loan.
        Your NFT is held in a smart contract and returned once the loan is repaid.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Al Goanna (v1)</h4>
        <p>Can take a loan up to 90 days</p>
        <p>Loan amount is: 2000A</p>
        <p>APR is 10%</p>
        <p>Effective interest rate for the loan period is 2.47%</p>
        <p>Interest payable is 49.32A</p>
        <p>Total Amount repayable is 2049.32A.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Mutants vs. Zombies (v2)</h4>
        <p>Can take a loan up to 90 days</p>
        <p>Loan amount is: 50A</p>
        <p>APR is 10%</p>
        <p>Effective interest rate for the loan period is 2.47%</p>
        <p>Interest payable is 1.23A</p>
        <p>Total Amount repayable is 51.23A.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>How does this all work?</h4>
        <p>We give NFTs a value based on their current floor price and distribution.
              We offer a percentage of that floor price whilst holding your NFT as collateral.
              You pay a set APR for up to 6 months, and we return the NFT back to you once the
              loan has been paid in full.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Where do the funds come from?</h4>
        <p>All liquidity is supplied by the Goanna DAO.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Where does the interest go?</h4>
        <p>The interest goes exclusively to the Goanna DAO.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Which NFTs can I use to take a loan?</h4>
        <p>The original Al Goanna (V1) NFTs are able to take a loan of 2000A.</p>
        <p>The Goanna MvZ (v2) NFTs are able to take a loan of 50A.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>How long are the loans for?</h4>
        <p>Loans are for a set period of up to 90 days. You are able to repay loans at
          any time but will pay the interest calculated on the full loan term.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>How much is the interest rate?</h4>
        <p>The APR is 10% with an effective interest rate for the 90 day period of approx 2.47%.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Can I take more than one loan?</h4>
        <p>Yes, you can take loans against any eligible NFTs you own.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>What is the cooldown period?</h4>
        <p>After repaying a loan, the same NFT cannot be used to take another loan for 3 days.
          This is to allow everyone an equal chance to utilize the bank.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>How do I earn $GOAN?</h4>
        <p>For taking a loan and successfully repaying it, the user will receive a reward of $GOAN if they.
        are opted in. Please opt in prior to repaying your loan (ASA: 1387238831).</p>
        <p>Repaying a 2k Algo loan against a V1 user receives: 2466 $GOAN</p>
        <p>Repaying a 50a loan against an MvZ user receives: 61.5 $GOAN</p>
        <hr className='my-5' />
        <h4 className='text-brand'>What happens if I don't repay in time?</h4>
        <p>The Goanna DAO will obtain ownership of the NFT and may sell it.
          We recommend that you set reminders to pay your loans on time.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Can I change the term of my loan?</h4>
        <p>Not at this time. But you can repay early at any time.</p>
        <hr className='my-5' />
        <h4 className='text-brand'>Disclaimer</h4>
        <p>The Goanna lending platform is run by the Goanna DAO community.
          The Goanna NFT Project and Shufl Labs are not responsible for this platform.
          If you use this service, you are taking a risk.
          The Goanna DAO is not responsible for any losses you may experience while using this platform.
          You are responsible for paying back your loans on time.
          If you don't, you may lose your assets.
          The Goanna DAO suggests that users understand the risks of decentralized technology before
          using the platform.</p>
      </div>
    </div>
  );
};

export default FAQ;
