import React from 'react';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

import {openLendModal, openPayModal} from 'Root/services/actions/actions';
import {myAlgoWallet, walletConnect} from 'Root/services/reducers/connect/connect';

import AlgoImage from 'Root/images/algo_white.png';

const NFTCard = ({
  showLendModal,
  showPayModal,
  id,
  loanId,
  name,
  collection,
  image,
  rank,
  loanamount,
  price,
  isOwner,
  reload,
  amount = 1,
  loanLength, endDate,
}) => {
  /**
  * Function to show nice date/ time
  * @param {number} UNIX_TIMESTAMP - The UNIX timestamp to convert.
  * @return {string} The formatted date/time string.
  */
  function timeConverter(UNIX_TIMESTAMP) {
    const a = new Date(UNIX_TIMESTAMP * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    const time = date + ' ' + month + ' ' + year + ' // ' + hour + ':' + min + ':' + sec;
    return time;
  }
  return (
    <>
      <div className="nft-item col-12 col-sm-6 col-md-4 col-xl-3">
        <div className="card text-body bg-dark border border-dark h-100 position-relative">
          {image?.endsWith('.webm') || image?.endsWith('.mp4') ?
          (
            <video className='thumbnail card-img-top' autoPlay loop>
              <source src={image} type={'video/webm'} />
            </video>
          ) :
          (
            <div className="thumbnail card-img-top" style={{
              backgroundImage: `url("${image}")`,
            }}>
              <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                width="800px" height="auto"
                className="card-img-top" alt=""/></div>
          )}
          <div className="card-body p-3">
            <p className="mb-0 fw-bold">{name} {amount > 1 && (`x${amount}`)}</p>
            <small className="text-body-25">{id}</small>
            <p className="mb-0 fw-bold">
              <small className="text-body-25">{(rank !== null)? rank : null}</small>
            </p>
          </div>
          {isOwner && (price && loanId && loanLength && (
            <div className="card-footer border-top border-dark p-3">
              <div className="row align-items-end g-0">
                <div className='col'>
                  <small className="text-muted">Length:</small>
                </div>
                <div className='col-auto'>
                  {loanLength/86400} Days
                </div>
                <div className='clearfix'></div>
              </div>
            </div>
          ))}
          {isOwner && (price && loanId && endDate && (
            <div className="card-footer border-top border-dark p-3">
              <div className="row align-items-end g-0">
                <div className='col'>
                  <small className="text-muted">Ends:</small>
                </div>
                <div className='col-auto'>
                  {timeConverter(endDate)}
                </div>
                <div className='clearfix'></div>
              </div>
            </div>
          ))}
          <div className="card-footer border-top border-dark p-3">
            <div className="row align-items-end g-0">
              <div className="col"><small className="text-muted">{loanId ? 'Loan' : 'Our'} Value:</small><br/>
                <img src={AlgoImage} width="10px" className="align-baseline" alt="$ALGO"/>
                &nbsp;{price > 0 ? price : loanamount}</div>
              {isOwner && (price && loanId ? (
                  <div className="col-auto text-start"><button className="btn btn-info px-3 rounded-pill"
                    onClick={(e) => {
                      e.preventDefault(); showPayModal(id, loanId, price, image, name, collection, reload);
                    }} >Pay</button></div>
                ) : (
                  !price && (
                    <>
                      <div className="col-auto text-start"><button className="btn btn-primary px-3 rounded-pill"
                        onClick={(e) => {
                          e.preventDefault(); showLendModal(id, image, name, collection, loanamount, reload);
                        }} >Loan</button></div>
                    </>
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  address: state.connect?.address,
  myAlgoWallet: myAlgoWallet,
  walletConnect: walletConnect,
});

const mapDispatchToProps = (dispatch) => ({
  showLendModal: (
      id,
      image,
      name,
      collection,
      loanamount,
      reload) => dispatch(
      openLendModal(
          id,
          image,
          name,
          collection,
          loanamount,
          reload,
      ),
  ),
  showPayModal: (id, loanId, loanPaidAmount, image, name, collection, reload) =>
    dispatch(openPayModal(id, loanId, loanPaidAmount, image, name, collection, reload)),
});

NFTCard.propTypes = {
  showLendModal: PropTypes.func,
  showPayModal: PropTypes.func,
  id: PropTypes.number,
  loanId: PropTypes.number,
  name: PropTypes.string,
  collection: PropTypes.string,
  image: PropTypes.string,
  rank: PropTypes.number,
  loanamount: PropTypes.number,
  price: PropTypes.number,
  isOwner: PropTypes.bool,
  reload: PropTypes.func,
  small: PropTypes.bool,
  amount: PropTypes.number,
  loanLength: PropTypes.number,
  endDate: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(NFTCard);
