import React, {useRef, useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {SHOW_LEND_MODAL, SHOW_LOADING_MODAL} from 'Root/services/constants/ActionTypes';
import {openWalletConnectModal, openLoadingModal, closeModal} from 'Root/services/actions/actions';
import {myAlgoWallet, peraConnect, deflyConnect, walletConnect} from 'Root/services/reducers/connect/connect';

import {signTransactions} from 'Root/helpers/signTransactions';

import toast from 'react-hot-toast';

import algosdk from 'algosdk';

import axios from 'axios';

import AlgoImage from 'Root/images/algo.png';

const LendModal = ({address, lendModal, closeModal, showWalletConnectModal, showLoadingModal,
  peraConnect, deflyConnect, myAlgoWallet, walletConnect}) => {
  if (!lendModal) {
    return null;
  }

  const [timeFrame] = useState(2);
  const [loading, setLoading] = useState(true);
  const [hasActiveLoan, setHasActiveLoan] = useState(false);
  const [hasActiveLoanMsg, setHasActiveLoanMsg] = useState('');

  useEffect(() => {
    /**
   * Fetches the active loan asynchronously.
   * @return {Promise<void>}
   */
    async function fetchActiveLoan() {
      try {
        // You can await here
        await getLastLoan();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchActiveLoan();
  }, []);

  const getLastLoan = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}getLastLoan?
public_key=${address}&id=${lendModal.id}`);
      const lastLoanTimestamp = response.data;
      // Calculate the current timestamp
      const currentTimestamp = Math.floor(Date.now() / 1000);
      // Calculate the time difference in seconds
      const timeDifference = currentTimestamp - lastLoanTimestamp;
      // Calculate remaining time until another loan can be taken (in seconds)
      const remainingTimeSeconds = 259200 - timeDifference;
      // Convert remaining time to days, hours, minutes
      const days = Math.floor(remainingTimeSeconds / (24 * 3600));
      const hours = Math.floor((remainingTimeSeconds % (24 * 3600)) / 3600);
      const minutes = Math.floor((remainingTimeSeconds % 3600) / 60);
      // Check if the last loan was within the allowed cooldown period (3 days = 259200 seconds)
      const withinCooldownPeriod = timeDifference < 259200;
      // Set hasActiveLoan based on cooldown period
      setHasActiveLoan(withinCooldownPeriod);
      setHasActiveLoanMsg(`${days}
          days ${hours} hours ${minutes} minutes until NFT Cooldown is complete`);
    } catch (error) {
      setHasActiveLoan(true);
      setHasActiveLoanMsg(`Error fetching last loan`);
      console.error('Error fetching last loan:', error);
      // Handle error
    }
  };

  /**
  * Function to send an NFT to a created listing
  * @param {number} nftId
  * @param {number} loanLengthIndex loan length index
  */
  async function lend(nftId, loanLengthIndex) {
    // Check if there's an active loan
    if (hasActiveLoan) {
      toast.error('You already took a loan for this NFT in the last 3 days.');
      return;
    }
    nftId = parseInt(nftId)
    let loanLength;

    switch (loanLengthIndex) {
      case 1:
        loanLength = 5184000;
        break;
      case 2:
        loanLength = 7776000;
        break;
      default:
        loanLength = 7776000;
    }

    showLoadingModal();
    const appId = await axios.get(process.env.REACT_APP_API_ADDRESS +
      `getFreeLoan`)
        .then((res) => res.data)
        .catch(() => {
          toast.error('No Available Loans.');
        })
        .finally(() => {
          closeModal(SHOW_LOADING_MODAL);
        });


    const paramsResponse = await axios.get(process.env.REACT_APP_API_ADDRESS + 'getParams');
    const params = paramsResponse.data;

    const appArgs = [
      new Uint8Array(Buffer.from('lend')),
    ];

    const lendTransaction = algosdk.makeApplicationNoOpTxnFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      appIndex: parseInt(appId),
      appArgs: appArgs,
      foreignAssets: [nftId],
    });

    lendTransaction.fee = 2000;

    const assetTransferTransaction = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      suggestedParams: {
        ...params,
      },
      from: address,
      to: algosdk.getApplicationAddress(parseInt(appId)),
      assetIndex: nftId,
      amount: 1,
    });


    const txnsToGroup = [lendTransaction, assetTransferTransaction];

    const groupID = algosdk.computeGroupID(txnsToGroup);
    for (let i = 0; i < txnsToGroup.length; i++) txnsToGroup[i].group = groupID;

    const transactionsToSend = await signTransactions(
        address, peraConnect, deflyConnect, myAlgoWallet, walletConnect,
        showWalletConnectModal, closeModal, txnsToGroup,
    );

    if (transactionsToSend.length > 0) {
      showLoadingModal();
      await axios.post(process.env.REACT_APP_API_ADDRESS + 'lend',
          {
            'lendTransactions': transactionsToSend,
            'loanLength': loanLength,
            'nftId': nftId,
            'loanId': appId,
          })
          .then(() => {
            toast.success('Loaned NFT Successfully!');
            lendModal.reload();
          })
          .catch(() => {
            toast.error('NFT Loan Failed. Check Your Balance.');
          })
          .finally(() => {
            closeModal(SHOW_LOADING_MODAL);
            closeModal(SHOW_LEND_MODAL);
          });
    } else {
      closeModal(SHOW_LOADING_MODAL);
      closeModal(SHOW_LEND_MODAL);
    }
  }

  const modalRef = useRef();

  const closeModalBackground = (e) => {
    if (modalRef.current === e.target) {
      closeModal(SHOW_LEND_MODAL);
    }
  };

  const keyPress = useCallback((e) => {
    if (e.key === 'Escape') {
      closeModal(SHOW_LEND_MODAL);
    }
  }, [closeModal]);

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  });


  return (
    <>
      { lendModal.type === SHOW_LEND_MODAL &&
        <div ref={modalRef} onClick={closeModalBackground} className='modal fade show d-inline' id='lendModal'>
          <div className='modal-dialog modal-dialog-centered modal-lg'>
            <div className='modal-content rounded-big bg-dark border-dark'>
              <div className='modal-header border-0 text-body'>
                <h6 className='modal-title text-truncate' id='lendModalLabel'>
                  <small className='d-block light-weight text-brand'>Lend NFT:</small>{lendModal.name}</h6>
                <button type='button' className='btn-close' onClick={() => closeModal(SHOW_LEND_MODAL)}></button>
              </div>
              <div className='modal-body border-0 p-3 px-md-4 pt-0 text-body'>
                <div className='row align-items-end my-3 g-3'>
                  <div className='col-12 col-md-6'>
                    <img className="img-fluid rounded-big mb-3" src={lendModal.image} />
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='input-group d-none'>
                      <span className='input-group-text bg-body-dark border-body-light' id='list-addon'>
                        <img src={AlgoImage} className='invert' width='10px'/>
                      </span>
                      <input id='listInput' onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          lend(lendModal.id, timeFrame);
                        }
                      }} type='number' className='form-control bg-body-dark border-body-light text-body'
                      placeholder='Algo lend price...' aria-label='Lend' aria-describedby='list-addon' />
                    </div>
                    <label htmlFor="timeframe" className="form-label"><strong>Loan length:</strong>
                      <span className='text-brand ps-2'>
                        {timeFrame === 2 && '90 Days'}
                      </span>
                    </label>
                    <br/>
                    <strong>Total repayable:</strong><br/>
                    <div className='col'>
                      <span className='text-brand ps-2'>
                        {timeFrame === 2 && `${lendModal.loanamount} $ALGO + 10% APR = 
                        ${(lendModal.loanamount+(lendModal.loanamount * (7776000/31536000)) * 0.10).toFixed(2)} $ALGO`}
                      </span>
                    </div>
                    <div className='col text-center mt-3'>
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {hasActiveLoan ? (
                            <p>{hasActiveLoanMsg}</p>
                          ) : (
                            <button onClick={() => {
                              lend(lendModal.id, timeFrame);
                            }} type='button' className='btn btn-primary px-3' data-bs-dismiss='modal'>Lend</button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-12 pt-3 text-center text-muted'>
                    <small>Be sure to double-check your loan length.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  lendModal: state.lendModal,
  address: state.connect?.address,
  peraConnect: peraConnect,
  deflyConnect: deflyConnect,
  myAlgoWallet: myAlgoWallet,
  walletConnect: walletConnect,
});

const mapDispatchToProps = (dispatch) => ({
  showWalletConnectModal: () => dispatch(openWalletConnectModal()),
  showLoadingModal: () => dispatch(openLoadingModal()),
  closeModal: (id) => dispatch(closeModal(id)),
});

LendModal.propTypes = {
  address: PropTypes.string,
  lendModal: PropTypes.object,
  showWalletConnectModal: PropTypes.func,
  showLoadingModal: PropTypes.func,
  closeModal: PropTypes.func,
  peraConnect: PropTypes.object,
  deflyConnect: PropTypes.object,
  walletConnect: PropTypes.object,
  myAlgoWallet: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(LendModal);
