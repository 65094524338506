import React from 'react';

import {Routes, Route} from 'react-router-dom';

import './App.scss';

import Navbar from './components/Navbar/Navbar';

import Welcome from 'Pages/welcome';
import Bank from 'Pages/bank';
import Wallet from 'Pages/wallet';
import NotFound from './pages/not-found';

import Faq from './pages/faq';
import Terms from './pages/terms';
import Privacy from './pages/privacy';

import ConnectModal from 'Components/Modals/ConnectModal/ConnectModal';
import WalletConnectModal from 'Components/Modals/WalletConnectModal/WalletConnectModal';
import LoadingModal from 'Components/Modals/LoadingModal/LoadingModal';
import LendModal from 'Components/Modals/LendModal/LendModal';
import PayModal from 'Components/Modals/PayModal/PayModal';

/**
 * App.
 * @return {React.Component}
 */
export default function App() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };
  window.addEventListener('resize', appHeight);
  appHeight();

  return (
    <>
      <LoadingModal />
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Welcome />} />
        <Route exact path='/bank' element={<Bank />} />
        <Route exact path='/account' element={<Wallet />} />
        <Route exact path='/faq' element={<Faq />} />
        <Route exact path='/terms' element={<Terms />} />
        <Route exact path='/privacy' element={<Privacy />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ConnectModal />
      <WalletConnectModal />
      <LendModal />
      <PayModal />
    </>
  );
}
