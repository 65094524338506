import React from 'react';

import NotFoundSection from '../components/Sections/NotFoundSection/NotFound';

const NotFound = () => {
  return (
    <>
      <div className="content py-5 box-content">
        <NotFoundSection />
      </div>
    </>
  );
};

export default NotFound;
